import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const [jwtToken, setJwtToken] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fetchUserDetails = useCallback(async (token) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/details-from-token`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const data = await response.json();
        setUserDetails(data.user);
      } else if (response.status === 401) {
        alert("Your session has expired. Please log in again.");
        navigate("/login");
      } else {
        console.error("Failed to fetch user details");
        navigate("/login");
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
      alert("An error occurred. Please try again.");
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    const token = localStorage.getItem("idToken");
    //console.log("Token from localStorage:", token);

    if (!token) {
      navigate("/login");
    } else {
      setJwtToken(token);
      setLoading(true);
      fetchUserDetails(token).finally(() => setLoading(false));
    }
  }, [navigate, fetchUserDetails]);

  const logout = () => {
    localStorage.clear();
    alert("You have been logged out.");
    navigate("/login");
  };

  const handleNavigation = (path) => () => navigate(path);

  return (
    <div>
      <h2>Welcome to the Home Page</h2>
      {loading ? (
        <p>Loading user details...</p>
      ) : jwtToken ? (
        <>
          {userDetails ? (
            <div>
              <p><strong>Email:</strong> {userDetails?.email}</p>
              <p><strong>Status:</strong> {userDetails?.status}</p>
            </div>
          ) : (
            <p>No user details found.</p>
          )}
          <button onClick={logout}>Logout</button>
          <div>
            <button onClick={handleNavigation("/update-profile")}>Update Profile</button>
            <button onClick={handleNavigation("/add-student")}>Add Student</button>
            <button onClick={handleNavigation("/add-tournament")}>Add Tournament</button>
          </div>
        </>
      ) : (
        <p>You are not logged in.</p>
      )}
    </div>
  );
};

export default Home;

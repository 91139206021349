import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AWS from "aws-sdk";
import axios from "axios";
import awsConfig from "./awsConfig";

AWS.config.update({ region: awsConfig.region });
const cognito = new AWS.CognitoIdentityServiceProvider();

const Register = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    countryCode: "+1",
    phone: "",
    password: "",
    confirmPassword: "",
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const registerUser = async () => {
    if (formData.password !== formData.confirmPassword) {
      alert("Passwords do not match!");
      return;
    }

    const phoneNumber = `${formData.countryCode}${formData.phone}`;

    const cognitoParams = {
      ClientId: awsConfig.clientId,
      Username: formData.email,
      Password: formData.password,
      UserAttributes: [
        { Name: "name", Value: formData.name },
        { Name: "email", Value: formData.email },
        { Name: "phone_number", Value: phoneNumber },
      ],
    };

    try {
      // Register the user in Cognito
      await cognito.signUp(cognitoParams).promise();

      alert("Registration successful! Please check your email for the OTP.");

      // Send the data to the backend
      const backendUrl = process.env.REACT_APP_BACKEND_URL; // Use the environment variable
      await axios.post(
        `${backendUrl}/api/users/register`,
        {
          user_email: formData.email,
          user_password: formData.password,
          user_role: "teacher",
          user_first_name: formData.name.split(" ")[0] || "",
          user_last_name: formData.name.split(" ")[1] || "",
          user_country_code: formData.countryCode,
          user_phone_number: formData.phone,
          user_login_mode: "direct",
        }
      );

      navigate("/verify-otp", { state: { email: formData.email } });
    } catch (error) {
      console.error("Error during registration:", error);
      alert("Registration failed. Please try again.");
    }
  };

  return (
    <div>
      <h2>Register</h2>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          registerUser();
        }}
      >
        <input
          type="text"
          name="name"
          placeholder="Full Name"
          value={formData.name}
          onChange={handleChange}
          required
        />
        <input
          type="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="countryCode"
          placeholder="Country Code"
          value={formData.countryCode}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="phone"
          placeholder="Phone Number"
          value={formData.phone}
          onChange={handleChange}
          required
        />
        <input
          type="password"
          name="password"
          placeholder="Password"
          value={formData.password}
          onChange={handleChange}
          required
        />
        <input
          type="password"
          name="confirmPassword"
          placeholder="Confirm Password"
          value={formData.confirmPassword}
          onChange={handleChange}
          required
        />
        <button type="submit">Register</button>
      </form>
    </div>
  );
};

export default Register;
